<template>
  <footer class="new-footer">
    <div class="new-footer-inner">
      <!-- footer cont area -->
      <section class="footer-content">
        <!-- top -->
        <div class="footer-cont top-area">
          <div class="cont-left cont-button">
            <button type="button" class="btn-top btn-scrollTop" @click="winTop">
              <i class="ico-arr">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 19V5"
                    stroke="#2F363D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 12L12 5L5 12"
                    stroke="#2F363D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </i>
              <span class="txt">Scroll to Top</span>
            </button>
          </div>
          <privacy :dialogVisible.sync="dialogVisible" />
          <div class="cont-right cont-article">
            <a href="#" class="link-privacy" @click.prevent="dialogVisible = true">
              <span class="txt">개인정보취급방침</span>
            </a>
          </div>
        </div>
        <!-- bottom -->
        <div class="footer-cont bottom-area">
          <div class="cont-left cont-social">
            <div class="tit">
              Let’s Connect <br />
              With <span class="color2">Emotion Global</span>
            </div>
            <ul class="list">
              <!-- 버튼 추가 -->
              <li>
                <a href="https://www.behance.net/emotionglobal" target="_blank" title="새창열림">
                  <span class="txt">Behance</span>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/emotion.co.kr/" target="_blank" title="새창열림">
                  <span class="txt">Facebook</span>
                </a>
              </li>
              <li>
                <router-link to="/careers" @click.native="$emit('update:navActive', false)">
                  <span class="txt">Careers</span>
                </router-link>
              </li>
              <li>
                <router-link to="/about" @click.native="$emit('update:navActive', false)">
                  <span class="txt">About</span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="cont-right cont-info">
            <ul>
              <li class="info-address">
                <div>5F, 637, Eonju-ro, Gangnam-gu,<br />Seoul, Republic of Korea</div>
                <div>T 02.542.1987 F 02.542.1988</div>
              </li>
              <li class="info-email">
                <a href="mailto:inquiry@emotion.co.kr">inquiry@emotion.co.kr</a>
              </li>
              <li class="info-copyright">ⓒ e · motion Global Inc. All rights reserved.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </footer>
  <!-- <footer>
    <h1>
      <router-link to="/">
        MAKE<br />
        GREATNESS<br />
        <span class="e-motion">e <span>.</span> motion</span>
      </router-link>
    </h1>

    <privacy :dialogVisible.sync="dialogVisible" />
    <div class="info-box">
      <a href="#" @click.prevent="dialogVisible = true">개인정보취급방침</a>
      <p class="copy">ⓒ e <span>.</span> motion Global Inc. All rights reserved.</p>
    </div>
    <button type="button" class="btn-top" @click="winTop">top</button>
  </footer> -->
</template>
<script>
  import privacy from "@/views/privacy/index";
  export default {
    name: "AppFooter",
    data() {
      return {
        dialogVisible: false
      };
    },
    components: {
      privacy
    },
    methods: {
      winTop() {
        window.scrollTo({top: 0, behavior: "smooth"});
      }
    }
  };
</script>
<style lang="scss" scoped>
  .new-footer {
    background-color: #eeeeee;
    overflow: hidden;

    .new-footer-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 1920px;
      margin: 0 auto;
      padding-bottom: 80px;
      * {
        @include montserrat();
      }
    }

    // Footer 컨텐츠
    .footer-content {
      padding: 40px 20px;

      .footer-cont {
        display: flex;
        justify-content: space-between;

        &.top-area {
          align-items: center;
          margin-bottom: 60px;
        }
        &.bottom-area {
          // align-items: flex-end;
          flex-direction: column;
        }
      }

      .btn-top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        column-gap: 8px;
        outline: none;
        border: none;
        background: transparent;
        .ico-arr {
          display: block;
          width: 18px;
          height: 18px;
          transform: translate3d(0, 0, 0);
          transition: 0.2s ease-in;
          animation: yoyoUp 0.3s infinite alternate;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          font-size: 12px;
          font-weight: 500;
          color: #000;
        }

        // hover
        // &:hover {
        //   animation: yoyoUp .3s infinite alternate;
        //   .ico-arr {
        //     animation: yoyoUp .3s infinite alternate;
        //     svg path {
        //       stroke: $red;
        //     }
        //   }
        //   .txt {
        //     color: $red;
        //   }
        // }
      }

      .link-privacy {
        position: relative;
        display: flex;
        align-items: center;

        .txt {
          color: #000;
          font-family: "Noto Sans KR";
          font-size: 12px;
          font-weight: 500;
          transition: 0.2s ease-in;
        }

        // hover
        &:hover {
          .ico-arr {
            transform: translate3d(0, -3px, 0);
            svg path {
              stroke: $red;
            }
          }
          .txt {
            color: $red;
          }
        }
      }

      // 소셜링크
      .cont-social {
        .tit {
          position: relative;
          font-size: 16px;
          font-weight: 600; // semiBold
          line-height: 1.4;
          letter-spacing: -0.01em;
          .color2 {
            color: $red;
          }
          &::after {
            content: "";
            position: absolute;
            top: -14px;
            left: 0;
            display: block;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $red;
          }
        }
        .list {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          margin-top: 20px;

          a {
            position: relative;
            display: inline-block;

            .txt {
              display: inline-flex;
              color: #000;
              font-size: 14px;
              font-weight: 500;
              transform: translateX(0);
              transition: 0.2s ease-in;
            }

            // &:hover {
            //   .txt {
            //     transform: translateX(10px);
            //     color: $red;
            //   }
            // }
          }
        }
      }

      // 회사정보
      .cont-info {
        margin-top: 20px;
        text-align: right;
        ul {
          display: flex;
          flex-direction: column;
          row-gap: 14px;
        }
        .info-address {
          font-size: 12px;
          line-height: 1.5;
          div + div {
            margin-top: 8px;
          }
        }
        .info-email a {
          color: #555;
          font-size: 12px;
          &:hover {
            color: $red;
          }
        }
        .info-copyright {
          font-size: 10px;
        }
      }
    }

    // Footer 애니메이션
    .footer-animation {
      padding: 0 120px;
      padding-bottom: 40px;
      overflow: hidden;
      border-bottom: 1px solid #eee;

      article.svg {
        position: relative;
        user-select: none;
        cursor: auto;

        .footer-copy {
          position: relative;
          display: flex;
          justify-content: center;
          width: 100%;
          line-height: 1;
          white-space: nowrap;
          transform-origin: center;
          perspective: 800px;
          transform-style: preserve-3d;

          ._char {
            position: relative;
            display: block;
            color: $red;
            @include montserrat();
            font-size: clamp(32px, 8.8vw, 40px);
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            transform-origin: center;
          }
        }
      }
    }
  }
</style>
